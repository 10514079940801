//import "core-js/stable";
//import "regenerator-runtime/runtime";

//import "../polyfills/prepend"


//import { LocalStorage } from "../commonUtils/localStorage"
import { CookieStorage } from "../commonUtils/cookieStorage"
import { KeepIdaasSessionAlive } from "./keepIdaasSessionAlive"
import { envConfig } from "./config"



export const keepIdaasAlive = function (idaasConfig, isSessionActiveCallback) {
    try {
        //const store = new LocalStorage(); 
        const store = new CookieStorage(idaasConfig.cookieBaseDomain); 
        if (store) {
            const validatedIdaasConfig = validate(idaasConfig, isSessionActiveCallback);
            const keepIdaasSesionAlive = new KeepIdaasSessionAlive(validatedIdaasConfig, store, isSessionActiveCallback);
            keepIdaasSesionAlive.startInterval();
        }
    } catch(e) {
        console.error(e);
    }

}

const validate = function (config, isSessionActiveCallback) {
    if(!(isSessionActiveCallback instanceof Function)) {
        throw new Error("isSessionActiveCallback object is not a Function");
    }
    if(!config.expiryStoreName) {
        config.expiryStoreName = envConfig.expiryStoreName;
    }
    if(!config.sessionKeepAliveTimer) {
        config.sessionKeepAliveTimer = envConfig.sessionKeepAliveTimer;
    }
    return config
}

//window.IGREUtils = { keepIdaasAlive }