import { envConfig, ServerHashParamKeys } from "./config"

export class KeepIdaasSessionAlive {



    constructor(iDaasConfig, store, isSessionActiveCallback) {
        this.timer = parseInt(iDaasConfig.sessionKeepAliveTimer) * 60 * 1000;
        this.store = store;
        this.isSessionActiveCallback = isSessionActiveCallback;
        this.iDaasConfig = iDaasConfig;
        this.expiryStoreName = iDaasConfig.expiryStoreName;
        this.iframeForHashTimeOut = parseInt(envConfig.iframeForHashTimeOut) * 1000;
        this.iframeForPollingTimer = parseInt(envConfig.iframeForPollingTimer) * 1000;
        this.iframeId = envConfig.iframeId;
    }

    startInterval () {
        this.interval = setInterval(() => {
            this.keepAlive();
        }, this.timer);
    }

    stopInterval () {
        clearInterval(this.interval);
    }

    stopAllIntervals () {
        console.log("stopping all timers (idaas and iframe )");
        clearInterval(this.interval);
        clearInterval(this.iframeHashCheckInterval);
    }
    startIframeHashCheckInterval () {
        this.iframeForHashTimeExpiry = new Date().getTime() +  this.iframeForHashTimeOut;
        this.iframeHashCheckInterval = setInterval(() => {
            this.monitorIframeForHash();
        }, this.iframeForPollingTimer);
    }

    stopIframeHashCheckInterval () {
        clearInterval(this.iframeHashCheckInterval);
    }

    keepAlive = function () {
        if(this.canRefreshSession()) {
            if(this.refreshIdaasSession()) {
                this.startIframeHashCheckInterval();
            } else {
                console.log("stopping refresh idaas session")
                this.stopInterval();
            }            
        } else {
            console.log("stopping refresh idaas session")
            this.stopInterval();
        }
    }

    canRefreshSession = function () {
        if(this.isSessionActiveCallback()) {
            let expiredTime = 0;
            try {
                //expiredTime = parseInt(this.store.getItem(this.expiryStoreName), 10);
                if (this.store.getItem(this.expiryStoreName) !== undefined) {
                    expiredTime = parseInt(this.store.getItem(this.expiryStoreName), 10);
                } 
                
            } catch (e) {
                console.error(e);
            }
            let now = new Date().getTime();
            
            if (now < expiredTime) {
                console.log(new Date() + " Checking TimeOut :::: now: " + now + " < " + "expTime: " + expiredTime + " Not Expired") 
                return true;
            } else {
                console.log(new Date() + " Checking TimeOut :::: now: " + now + " > " + "expTime: " + expiredTime + " **Expired**") 
                return false;
            }
        }
        console.log("User Session is not Active");
        return false;
    }


    refreshIdaasSession = function () {
       try {
            let url = this.composeIdaasUrl();
            let existingFrame = document.getElementById(this.iframeId);
            if (existingFrame) {
                console.log("Refreshing idaas session ....");
                existingFrame.src = url + "&t=" + new Date().getTime();
            } else {
                console.log("Refreshing idaas session ..");
                let iframe = document.createElement("iframe");
                iframe.id = this.iframeId;
                iframe.style = "width: 0; height: 0; border: 0; border: none; position: absolute; display: none;";
                iframe.src = url;
                document.body.appendChild(iframe);
            }
            return true;
        } catch (e) {
            console.error(e);
            console.log("error creating iframe...");
        }
        return false;

    }

    composeIdaasUrl = function () {
        let redirectUri = encodeURIComponent(this.iDaasConfig.redirect_uri);
        let timestamp = new Date().getTime();
        let url = this.iDaasConfig.hostname + "/" + this.iDaasConfig.tenant + "/oauth2/v2.0/authorize?p=" + this.iDaasConfig.policy + "&client_id=" + this.iDaasConfig.client_id + "&response_type=" + this.iDaasConfig.response_type + "&redirect_uri=" + redirectUri + "&scope=openid&response_mode=fragment&state=" + timestamp + "&nonce=" + timestamp + "&prompt=none";
        return url;
    }

    monitorIframeForHash = function () {
        let now = new Date().getTime();
        if(this.iframeForHashTimeExpiry < now) {
            console.log("stopping iframe timer.TimedOut");
            this.stopIframeHashCheckInterval(); //stop iframe timer
            return;
        } 

        let iframeUrl = this.getIframeUrl();
        if (iframeUrl === "" || iframeUrl === "about:blank") {
            console.log("stopping iframe timer. Unable to read url or No url available");
            this.stopIframeHashCheckInterval(); //stop iframe timer
            return;
        }

        let params = this.deserializeHash(iframeUrl);  // returns the map of url parameters and value
        if(!params) {
            console.log("stopping iframe timer. Unable to read params iframe url or No url params found");            
            this.stopIframeHashCheckInterval(); //stop iframe timer
            return;
        }
        if(params.hasOwnProperty(ServerHashParamKeys.ERROR_DESCRIPTION) || params.hasOwnProperty(ServerHashParamKeys.ERROR)) {
            console.log("stopping iframe and session timer. Error from IDaas :: " + params[ServerHashParamKeys.ERROR_DESCRIPTION]);
            this.stopAllIntervals(); //error occured.. stop all timers
            return;
        }

        if(params.hasOwnProperty(ServerHashParamKeys.CODE) || params.hasOwnProperty(ServerHashParamKeys.ACCESS_TOKEN) || params.hasOwnProperty(ServerHashParamKeys.ID_TOKEN) ){
            console.log("stopping iframe timer. Success response from IDaas");
            this.stopIframeHashCheckInterval(); //success: stop iframe timer
            //return;
        }

    }

    getIframeUrl = function () {
        let iframeHref="";
        try {
            let iframeElement = document.getElementById(this.iframeId);
            iframeHref = iframeElement && iframeElement.contentWindow ? iframeElement.contentWindow.location.href : "";
        } catch (e) {
            console.log("Error fetching url hash from contentWindow");
            //console.error(e);
            iframeHref = "";
        }
        try {
            iframeHref = iframeHref.toString()
        } catch (e) {
            console.log("Error fetching url hash");
            //console.error(e);
            iframeHref = "";
        }
        return iframeHref;
    }




    deserializeHash = function (urlFragment) {
        const hash = this.getHashFromUrl(urlFragment);
        if(hash)
            return this.deserialize(hash);
        else
            return null;
    }

    deserialize = function (query) {
        let match = []; // Regex for replacing addition symbol with a space
        const pl = /\+/g;
        const search = /([^&=]+)=([^&]*)/g;
        const decode = (s) => decodeURIComponent(s.replace(pl, " "));
        let obj = {};
        match = search.exec(query);
        while (match) {
            obj[decode(match[1])] = decode(match[2]);
            match = search.exec(query);
        }
        return obj;
    }

    getHashFromUrl = function (urlStringOrFragment) {
        try {
            const hashIndex1 = urlStringOrFragment.indexOf("#");
            const hashIndex2 = urlStringOrFragment.indexOf("#/");
            if (hashIndex2 > -1) {
                return urlStringOrFragment.substring(hashIndex2 + 2);
            } else if (hashIndex1 > -1) {
                return urlStringOrFragment.substring(hashIndex1 + 1);
            }
            return urlStringOrFragment;
        } catch(e) {
            console.error(e);
            return null;
        }
    }
    
}