
export class CookieStorage {
  
    constructor(cookieBaseDomain) {
      this.cookieBaseDomain = cookieBaseDomain;
    }
  
 
    /*
     * Gets an item in the storage.
     *
     * @param value - The value to get.
     * @return The current value.
     */
    getItem(key) {
      return this.getCookie(key);
    }
  
    /*
     * Removes an item in the storage.
     *
     * @param value - The value to remove.
     */
    removeItem(key) {
      this.removeCookie(key);
    }
  
    /*
     * Sets an item in the storage.
     *
     * @param key - The key to set the value.
     * @param value - The value to set to the key.
     */
    setItem(key, data) {
      this.setCookie(key, data);
    }
  

    setCookie(name, value) {
        let baseDomain = this.cookieBaseDomain ? `; domain=${this.cookieBaseDomain}` : '';
        document.cookie = `${name}=${value}${baseDomain}; samesite=Lax; secure=true; path=/`;
    }

    removeCookie(name) {
        let baseDomain = this.cookieBaseDomain ? `; domain=${this.cookieBaseDomain}` : '';
        document.cookie = `${name}=${baseDomain}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    getCookie = function (name) {
        const splitCookies = document.cookie.split(';');
        const searchString = `${name}=`;
        for (const element of splitCookies) {
          const currentCookie = element.trim();
          if (currentCookie.indexOf(searchString) === 0) {
            return currentCookie.substring(searchString.length, currentCookie.length);
          }
        }
        return undefined;
      }
  }