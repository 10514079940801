export const envConfig = {
    // server-side session keep-alive timer
    sessionKeepAliveTimer: 15,   // minutes
    expiryStoreName: "igre.expiryTime",
    iframeForHashTimeOut: 10,  // seconds
    iframeForPollingTimer: 3,    // seconds
    iframeId: "IGREIdaasAuthIFrame"
}

export const ServerHashParamKeys =  {
  SCOPE : "scope",
  STATE : "state",
  ERROR : "error",
  ERROR_DESCRIPTION : "error_description",
  ACCESS_TOKEN : "access_token",
  ID_TOKEN : "id_token",
  EXPIRES_IN : "expires_in",
  SESSION_STATE : "session_state",
  CLIENT_INFO : "client_info",
  CODE: "code"
}